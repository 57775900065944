<template>
  <div
    v-if="showBanner"
    class="alert alert-warning shadow-lg mb-4 inline-block"
  >
    <div class="text-center mx-auto">
      <p>
        Para generar tu código QR e imprimirlo, por favor configurá tu perfil
        público (foto y nombre de pila).
      </p>
      <p class="mt-2">
        Luego desde "Código QR" en el menú de navegación podrás generarlo.
      </p>
      <div class="flex-none mt-3">
        <NuxtLink to="/dashboard/profile" class="btn btn-sm btn-primary"
          >Configurar perfil</NuxtLink
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const profileStore = useProfileStore();
const user = useSupabaseUser();
const route = useRoute();

const showBanner = computed(() => {
  return (
    profileStore.loaded &&
    !profileStore.areSettingsSet &&
    user?.value &&
    route.path !== "/dashboard/profile" &&
    route.path !== "/dashboard/payment"
  );
});
</script>
