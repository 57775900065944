<template>
  <div
    v-if="user && profileStore.loaded && showBanner"
    class="alert alert-warning inline-block shadow-lg mb-4"
  >
    <div class="text-center mx-auto">
      <p>
        Para recibir donaciones y activar la votación, por favor vinculá tu
        cuenta de Mercado Pago.
      </p>

      <p class="my-3">Si ya lo hiciste, por favor refrescá la página.</p>

      <div class="flex-none">
        <NuxtLink to="/dashboard/payment" class="btn btn-sm btn-primary"
          >Vincular MercadoPago</NuxtLink
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const profileStore = useProfileStore();
const user = useSupabaseUser();
const route = useRoute();

const showBanner = computed(() => {
  return (
    !profileStore.paymentActive &&
    user?.value &&
    route.path !== "/dashboard/payment" &&
    route.path !== "/dashboard/profile" &&
    route.path !== "/dashboard/mp-setup"
  );
});
</script>
